import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/img/logo.svg";
import Fb from "../Assets/img/fb.svg";
import Insta from "../Assets/img/insta.svg";
import X from "../Assets/img/x.svg";
import Linkedin from "../Assets/img/linkedin.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { FaEnvelope } from "react-icons/fa";

export default function Footer() {
  const socials = [
    {
      icon: Fb,
    },
    {
      icon: Insta,
    },
    {
      icon: X,
    },
    {
      icon: Linkedin,
    },
  ];

  const rooms = [
    {
      url: "/oasis/familysuite",
      title: "A/C Family Suite",
    },
    {
      url: "/oasis/deluxesuite",
      title: "A/C Deluxe Suite",
    },
    {
      url: "/oasis/executive",
      title: "Executive A/C Room",
    },
    {
      url: "/oasis/superior",
      title: "Superior A/C Room",
    },
  ];

  const quicklinks = [
    {
      url: "/",
      title: "Home",
    },
    {
      url: "/dining",
      title: "Dining",
    },
    {
      url: "/celebrate",
      title: "Celebrate",
    },
    {
      url: "/exploredurga",
      title: "Explore Chitradurga",
    },
    {
      url: "/blog",
      title: "Blog",
    },
    {
      url: "/contact",
      title: "Contact",
    },
    {
      url: "https://wa.me/919380321718",
      title: "Book A Stay",
    },
  ];
  return (
    <>
      <div className="footer ptb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mobcenter mobpb">
              <div>
                <div>
                  <Link to="/">
                    {" "}
                    <img src={Logo} alt="" className="img-fluid" />
                  </Link>
                </div>
                <div className="socialicons">
                  <div className="pt-4">
                    <h5>Follow Us</h5>
                  </div>
                  {socials.map((item, index) => (
                    <>
                      <span key={index}>
                        <Link to="">
                          <img src={item.icon} alt="" className="img-fluid" />
                        </Link>
                      </span>
                    </>
                  ))}
                </div>
                <div className="pt-4">
                  <small>
                    © 2024 <Link to="/">Hotel Aishwarya Naveen.</Link> <br />
                    All Rights Reserved.
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-3 mobcenter mobpb">
              <h6>Rooms</h6>
              <ul className="list-unstyled">
                {rooms.map((item) => {
                  return (
                    <li key={item.url}>
                      <Link to={item.url}>
                        <MdKeyboardArrowRight /> {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-md-3 mobcenter mobpb">
              <h6>Quick Links</h6>
              <ul className="list-unstyled">
                {quicklinks.map((item) => {
                  return (
                    <li key={item.url}>
                      <Link to={item.url}>
                        <MdKeyboardArrowRight /> {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-md-3 mobcenter">
              <h6>Reach Us</h6>
              <ul className="list-unstyled reach">
                <li>
                  <Link to="https://maps.app.goo.gl/FpSRYxKs3cFjQ5hk8">
                    <FaMapMarkerAlt />{" "}
                    <span>
                      Hotel Aishwarya Naveen, Turuvanur Rd, near RTO Office,
                      Maniyur, Chitradurga, Karnataka Pin code: 577501
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="tel:919380321718">
                    <MdCall /> <span>+91 93803 21718</span>
                  </Link>{" "}
                  {" | "}
                  <Link to="tel:918194230052">
                    <span>+91 81942 30052</span>
                  </Link>
                </li>
                <li>
                  <Link to="mailto:hotelAishwaryanaveen@gmail.com">
                    <FaEnvelope /> <span>hotelAishwaryanaveen@gmail.com</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
