import React from "react";
import OasisHeading from "../Assets/img/home/headings/oasis.svg";
import Family from "../Assets/img/home/family.png";
import Deluxe from "../Assets/img/home/deluxe.png";
import Executive from "../Assets/img/home/executive.png";
import Superior from "../Assets/img/home/superior.png";
import Button from "./Button";

export default function Oasis() {
  const secCards = [
    {
      img: Family,
      title: "A/C Family Suite",
      url: "/oasis/familysuite",
      btnTitle: "View",
    },
    {
      img: Deluxe,
      title: "A/C Deluxe Suite",
      url: "/oasis/deluxesuite",
      btnTitle: "View",
    },
    {
      img: Executive,
      title: "Executive A/C Room",
      url: "/oasis/executive",
      btnTitle: "View",
    },
    {
      img: Superior,
      title: "Superior Non A/C",
      url: "/oasis/superior",
      btnTitle: "View",
    },
  ];
  return (
    <>
      <div className="bgSec">
        <div className="section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <img src={OasisHeading} alt="" className="img-fluid" />
              </div>
              <div className="col-md-7 mt-5 pt-5">
                <p className="font-18">
                  Unwind in our luxurious rooms and suites, designed to pamper
                  your senses. Immerse yourself in comfort and elegance as you
                  rejuvenate for your journey.
                </p>
                {/* <Button url="/" title="View rooms" className="pt-3" /> */}
              </div>
              <div className="pt-5"></div>
              </div>
              <div className="row seccards">
                {secCards.map((item, index) => (
                  <div className="col-md-3" key={index}>
                    <div className="item text-center oasis">
                      <img src={item.img} alt="" />
                      <div className="head">
                        <small>{item.tag}</small>
                        <p>{item.title}</p>
                        <Button
                          url={item.url}
                          title={item.btnTitle}
                          className="pt-3 visible"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
    </>
  );
}
