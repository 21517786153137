import React from 'react'
import About from "../Assets/img/home/about.png";
// import Button from './Button';
export default function HomeAbout() {
  return (
    <>
      <p className="font-18 text-dark">
        Hotel Aishwarya Naveen, a cherished part of Chitradurga's landscape,
        invites you to experience warm hospitality. As the newest jewel in the
        Aishwarya Group, we've been delighting guests for over two decades.
      </p>
      <div className="row align-items-center pt-4 gap-5 justify-content-center">
        <div className="col-md-6">
          <img src={About} alt="" className="img-fluid box-shadow" />
        </div>
        <div className="col-md-4">
          <div className="cursive primary-text mobcenter">
            Let us be your home away from home.
          </div>
          {/* <Button url="/" title="Know more" className="pt-4 mt-2"/> */}
        </div>
      </div>
    </>
  );
}
