import React from "react";
import BG from "../Assets/img/bg.svg";
import HomeAbout from "../Components/HomeAbout";
import Culinary from "../Components/Culinary";
import Oasis from "../Components/Oasis";
import Celebrate from "../Components/Celebrate";
import Explore from "../Components/Explore";
import CTA from "../Components/CTA";
export default function Home() {
  return (
    <>
      <div className="hero">
        <img src={BG} alt="" className="img-fluid"/>
      </div>
      <div className="container section">
        <HomeAbout />
      </div>
      <Culinary />
      <Oasis />
      <Celebrate />
      <Explore />
      <CTA />
    </>
  );
}
