import React from 'react'
import { Link } from 'react-router-dom';

export default function SimilarRooms() {
  return (
    <>
      <div className="pt-5">
        <h5>All Rooms:</h5>
        <Link to="/oasis/familysuite">A/C Family Suite</Link> {" | "}
        <Link to="/oasis/deluxesuite">A/C Deluxe Suite</Link> {" | "}
        <Link to="/oasis/executive">Executive A/C</Link> {" | "}
        <Link to="/oasis/superior">Superior Non A/C</Link>
      </div>
    </>
  );
}
