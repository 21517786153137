import React from "react";
import ExploreHeading from "../Assets/img/home/headings/explore.svg";
import CD1 from "../Assets/img/home/ec1.png";
import CD2 from "../Assets/img/home/ec2.png";
import CD3 from "../Assets/img/home/ec3.png";
import CD4 from "../Assets/img/home/ec4.png";
import CD5 from "../Assets/img/home/ec5.png";
import CD6 from "../Assets/img/home/ec6.png";
import CD7 from "../Assets/img/home/ec7.png";
import CD8 from "../Assets/img/home/ec8.png";

export default function Explore() {
  const secCards = [
    {
      img: CD1,
      title: "DURGA FORT",
    },
    {
      img: CD2,
      title: "VANI VILASA SAGARA",
    },
    {
      img: CD3,
      title: "CHANDRAVALLI SITE",
    },
    {
      img: CD4,
      title: "HIMADHAGIRI FALLS",
    },
    {
      img: CD5,
      title: "TUPPADA KOLA",
    },
    {
      img: CD6,
      title: "AADUMALLESHWARA",
    },
    {
      img: CD7,
      title: "SRI MURUGA MATHA",
    },
    {
      img: CD8,
      title: "ONAKE OBAVVANA KINDI",
    },
  ];
  return (
    <>
      <div className="bgSec">
        <div className="section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12">
                <div className="row justify-content-center">
                  <div className="col-md-7">
                    <img src={ExploreHeading} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row justify-content-center">
                  <div className="col-md-8 pt-3 text-center">
                    <p className="font-18">
                      Discover the allure of Chitradurga.
                    </p>
                    <p className="font-18">
                      From the majestic Chitradurga Fort to the serene Vani
                      Vilas Sagar, there's something for everyone.
                    </p>
                    <p className="cursive primary-text">
                      Let us be your guide to this enchanting land.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5"></div>
            <div className="row seccards">
              {secCards.map((item, index) => (
                <div className="col-md-3 mb-4" key={index}>
                  <div className="item text-center">
                    <img src={item.img} alt="" />
                    <div className="head">
                      <p>{item.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
