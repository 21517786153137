import React from "react";
import CelebHeading from "../Assets/img/home/headings/celebrate.svg";
import Wedding from "../Assets/img/home/wedding.png";
import Corporate from "../Assets/img/home/corporate.png";
import Gathering from "../Assets/img/home/gathering.png";
import Button from "./Button";

export default function Celebrate() {
  const secCards = [
    {
      img: Wedding,
      title: "Wedding",
    },
    {
      img: Corporate,
      title: "corporate event",
    },
    {
      img: Gathering,
      title: "Intimate gathering",
    },
  ];
  return (
    <>
      <div className="bgSec sec3padding">
        <div className="sec3">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="row justify-content-center">
                    <div className="col-md-7">
                      <img src={CelebHeading} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row justify-content-center">
                      <div className="col-md-8 pt-3 text-center">
                        <p className="font-18 text-white">
                          Whether it's a grand wedding, a corporate conference,
                          or an intimate gathering, our versatile spaces are
                          perfect for any occasion.
                        </p>
                        <p className="cursive text-white">
                          Let us transform your vision into reality.
                        </p>
                        <Button url="/" title="Book an event" className="pt-3" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5"></div>
                <div className="row seccards celeb">
                  {secCards.map((item, index) => (
                    <div className="col-md-4" key={index}>
                      <div className="item text-center">
                        <img src={item.img} alt="" className="img-fluid"/>
                        <div className="head">
                          <small>{item.tag}</small>
                          <p>{item.title}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}
