import React from "react";
import CulinaryHeading from "../Assets/img/home/headings/culinary.svg";
import Breakfast from "../Assets/img/home/bf.png";
import Lunch from "../Assets/img/home/lunch.png";
import Tea from "../Assets/img/home/tea.png";
import Dinner from "../Assets/img/home/dinner.png";
// import Button from "./Button";

export default function Culinary() {
  const secCards = [
    {
      img: Breakfast,
      tag: "hearty",
      title: "breakfast",
    },
    {
      img: Lunch,
      tag: "delicious",
      title: "lunch",
    },
    {
      img: Tea,
      tag: "soothing",
      title: "evening tea",
    },
    {
      img: Dinner,
      tag: "EXQUISITE",
      title: "dinner",
    },
  ];
  return (
    <>
      <div className="bgSec sec1">
        <div className="section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <img src={CulinaryHeading} alt="" className="img-fluid" />
              </div>
              <div className="col-md-7 mp-0 mt-5 pt-5">
                <p className="font-18 text-white">
                  Savor the essence of Chitradurga on your palate. Our
                  restaurant offers a delightful fusion of traditional and
                  contemporary flavors. From hearty breakfasts to exquisite
                  dinners, every meal is a culinary masterpiece.
                </p>
                {/* <Button url="" title="View dining" className="pt-3"/> */}
              </div>
            </div>
            <div className="pt-5"></div>
            <div className="row seccards">
              {secCards.map((item, index) => (
                <div className="col-md-3" key={index}>
                  <div className="item text-center">
                    <img src={item.img} alt="" className="img-fluid" />
                    <div className="head">
                      <small>{item.tag}</small>
                      <p>{item.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
