import React from "react";
import CTAHeading from "../Assets/img/home/headings/cta_heading.svg";
import Button from "./Button";

export default function CTA() {
  return (
    <>
      <div className="bgSec cta">
        <div>
          <div className="section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-7">
                  <img src={CTAHeading} alt="" className="img-fluid" />
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-8 pt-3 text-center">
                  <p className="font-18 text-white">
                    Experience the Aishwarya Naveen difference.
                  </p>
                  <p className="cursive text-white">
                    Book your stay now and embark on an unforgettable journey.
                  </p>
                  <Button
                    url="https://wa.me/919380321718/"
                    title="Book a stay"
                    className="pt-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
